// NewsItem.js

import React, { Component } from 'react';
import './NewsItem.css';

class NewsItem extends Component {
  render() {
    const { title, description, imageUrl, newsUrl } = this.props;
    return (
      <div className="news-card">
        <div className="card">
          <img src={!imageUrl ? "https://dict.mizoram.gov.in/uploads/attachments/ff7a94802085555083b2086440a0a871/pillar.png" : imageUrl} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
            <a rel='noreferrer' href={newsUrl} target="blank" className="btn btn-dark">Read More</a>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsItem;

